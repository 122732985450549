// working string global variable for recursion cleanliness

const RESULT_LIMIT = 200000
const ENCODE_INPUT_LENGTH_LIMIT = 500
const DECODE_INPUT_LENGTH_LIMIT = 1000

export class Decoder {
  word: string;
  memo: string[][];

  constructor(word: string) {
    let w = word.toLowerCase()
      this.word = w;
      this.memo = Array(w.length).fill([]);
  }

  // given string of letters, encodes to string of digits
  encodeString: () => string = () => {
    if (this.word.length >= ENCODE_INPUT_LENGTH_LIMIT) {
      throw ValidationError(`Messages must be less than ${ENCODE_INPUT_LENGTH_LIMIT} characters`)
    }
    if (!this.word.match(/^[A-Za-z\s]+$/)) {
      throw ValidationError("Message may contain only English characters and spaces (ex. Hello)")
    }

    let w = this.word.toLowerCase()
    // Build result string
    let res = ""
    for (let i = 0; i < w.length; i++) {
      const c: number = w.charCodeAt(i)
      if (c === 32) {
        res += " "
      } else {
        res += (c - 96).toString() // c - 96 converts letter to encoded digit
      }
    }
  
    return res
  }

  // Given string of digits, returns possible alphabetical decodings 
  decodeString: () => string[] = () => {
    if (this.word.length >= DECODE_INPUT_LENGTH_LIMIT) {
      throw ValidationError(`Messages must be less than ${DECODE_INPUT_LENGTH_LIMIT} characters`)
    }
    if (!this.word.match(/^[0-9\s]+$/)) {
      throw ValidationError("Encoded message must contain only digits and spaces (ex. 85121215)")
    }

    let preCount = this.decodeNumWays(this.word)
    if (preCount > RESULT_LIMIT) {
      throw ComplexityError("Too many results to decode :(", preCount)
    }
    
    try {
      this.decodeStringRec(0)
    } catch (e) {
      if (isDecodingError(e)) {
      }
      throw e
    }

    const ans = this.memo[0]
    if (ans.length === 0) {
      throw ValidationError("No valid decodings to this message :(")
    }
    return ans
  }

  decodeStringRec: (i: number) => string[] = (i) => {
    if (i >= this.word.length) {
      return []
    }
    if (this.memo[i].length > 0) {
      return this.memo[i]
    }
    
    const buffer: string[] = []
    if (this.word[i] === " ") {
      if (i + 1 === this.word.length) {
        buffer.push(" ")
      } else {
        try {
          const suffixes = this.decodeStringRec(i + 1)
          for (let suff of suffixes) {
            buffer.push(" " + suff)
          }
        } catch (e) {
          throw e
        }
      }
      // console.log("found", buffer)
      this.memo[i] = buffer
      return buffer
    }

    // Check 1-digit number at index
    const singleDigitLetter = this.singleDigitToLetter(i)
    if (!singleDigitLetter) {
      // console.log("single digit invalid")
      // if single digit invalid, double digit will definitely be invalid
      return []
    }
    // try single digit
    if (i + 1 === this.word.length) {
      buffer.push(singleDigitLetter)
    } else {
      try {
        const suffixes = this.decodeStringRec(i + 1)
        for (let suff of suffixes) {
          buffer.push(singleDigitLetter + suff)
        }
      } catch (e) {
        throw e
      }
    }
    // Check if double digit letter fits
    if (i + 1 < this.word.length) {
      // Check 2-digit number at index
      const doubleDigitLetter = this.doubleDigitToLetter(i)
      if (doubleDigitLetter) {
        // try double digit
        if (i + 2 === this.word.length) {
          buffer.push(doubleDigitLetter)
        } else {
          try {
            const suffixes = this.decodeStringRec(i + 2)
            for (let suff of suffixes) {
              buffer.push(doubleDigitLetter + suff)
            }
          } catch (e) {
            throw e
          }
        }
      }
    }

    // console.log("found", buffer)
    this.memo[i] = buffer
    return buffer
  }

  charToDigit: (index: number) => number = (index) => {
    return (this.word.charCodeAt(index) - 48);
  }

  singleDigitIsLetter: (index: number) => boolean = (index) => {
    if (index >= this.word.length || index < 0) {
      return false
    }
    const c = this.word.charCodeAt(index)
    if (c === 48) {
      return false
    }
    return true
  }

  doubleDigitIsLetter: (index: number) => boolean = (index) => {
    if (index + 1 >= this.word.length || index < 0) {
      return false
    }
    const c1 = this.word.charAt(index)
    // zeroes must be preceded by a 1 or 2, otherwise digit string is invalid
    if (!(c1 === "1" || c1 === "2")) {
      return false
    }
    const c2 = this.word.charAt(index + 1)
    if (c2 === " ") {
      return false
    }
    
    if (c1 === "2" && c2 > "6") {
      return false
    }

    return true
  }

  singleDigitToLetter: (index: number) => string = (index) => {
    if (!this.singleDigitIsLetter(index)) {
      return ""
    }

    return String.fromCharCode(this.word.charCodeAt(index) + 16)
  }

  doubleDigitToLetter: (index: number) => string = (index) => {
    if (!this.doubleDigitIsLetter(index)) {
      return ""
    }

    const num = this.charToDigit(index) * 10 + this.charToDigit(index+1)

    return String.fromCharCode(num + 64)
  }

  decodeNumWays: (s: string) => number = (s) => {
    // handle edge cases
    if (this.word.length === 0) return 0;
    if (!this.singleDigitIsLetter(0)) return 0;
    if (this.word.length === 1) return 1;
    // initialize dp array
    const dp: number[] = new Array<number>(this.word.length).fill(0);
    dp[0] = 1;
    if (this.doubleDigitIsLetter(0)) {
      dp[1] = 2;
    } else if (this.word.charAt(1) === '0') {
      dp[1] = 0;
    } else {
      dp[1] = 1;
    }
    // fill dp array bottom up
    for (let i = 2; i < this.word.length; i++) {
      // check single letter
      if (this.word.charAt(i) === ' ' || this.singleDigitIsLetter(i)) {
        dp[i] = dp[i-1]
      }
      // check double letter
      if (this.doubleDigitIsLetter(i-1)) {
        dp[i] += dp[i-2]
      }
    }
    console.log("dp", dp)
    // return last value in dp array as result
    return dp[this.word.length-1]
  }
}


export interface DecodingError {
  e: Error,
  errType: 'validation' | 'complexity',
  data: any
}

export const isDecodingError = (e: unknown): e is DecodingError => {
  return (e as DecodingError).e !== undefined;
}

const ValidationError: (message: string) => DecodingError = (message) => {
  return {e: new Error(message), errType: 'validation', data: null}
}

const ComplexityError: (message: string, numWays: number) => DecodingError = (message, numWays) => {
  return {e: new Error(message), errType: 'complexity', data: numWays}
}

export const isProd: () => boolean = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    return false
  }
  // production code
  return true
}