import React, { useEffect } from 'react';
import './App.scss';
import CodingTool from './CodingTool';
import Footer from './Footer';
import InfoSection from './InfoSection';
import { isProd } from './utils';

const DEV_URL = "http://localhost:3000/"
const PROD_URL = "https://decoder.johnwhiteside.org/"

function App() {

  useEffect(() => {
    // Set title
    document.title = "3B's Cryptography Tool"
    // Set url to work w/ single route & CF redirect
    const url = window.location.toString()
    if (url !== DEV_URL && url !== PROD_URL) {
      window.location.replace(isProd() ? PROD_URL: DEV_URL)
    }
  }, [])
  
  return (
    <div className="App">
      <CodingTool />
      <InfoSection />
      <Footer />
    </div>
  );
}

export default App;
