import React from "react";

const InfoSection = () => {

  return (
    <div className="InfoSection">
      <h1>About</h1>
      <h2>Hello, Mr. Whiteside here!</h2>
      <p>In March of 2022, I was in a class where a student shared a secret message they had written, and challenged us with cracking the code.</p>
      <p>After a few naive attempts with no success, he shared with us how he created the message. The code was simple: each letter is assigned a number in alphabetical order (ex. A: 1, B: 2, ... , Z: 26). So, the message "hello" would become "85121215".</p>
      <p>In our next attempt, we <i>still</i> struggled to decode the message. For this message, like most, multiple valid decodings could be created. Even if you decode all the possible messages, finding the <i>correct</i> one is not always easy. I mentioned that "hello" will encode to "85121215"; this can be decoded into 13 different messages, including helabo, heababae, and hello!</p>
      <p>I thought that we could use some help to decode these messages quickly, so I created this tool to help. I wrote a few algorithms, then created this web page to put them to work.</p>
      <h2>What are algorithms?</h2>
      <p>An <b>algorithm</b> is a step-by-step procedure used to solve a problem.</p>
      <p>The algorithms are used to encode and decode messages. Encoding messages is easy; we simply map letters to their corresponding number in the alphabet. Decoding these messages, however, requires much more work. In order to fully decode a message, <b>every possible decoding</b> must be generated by the algorithm.</p>
      <p>Now, when an encoded message yields over 100,000 possible decodings, the algorithm becomes sluggish. Even for a computer, it is difficult to decode and generate that many messages. So, do we just call it quits and cap it at 100K results? Or, do we just do nothing, and have a slow application?</p>
      <p>None of the above! Using another algorithm, we can calculate the total number of results that the message can decode into, without actually decoding the messages. This requires much less work, and can be accomplished relatively quickly. Using this algorithm, we can show the user <i>why</i> the message is too complex to decode, without slowing down our application. Try typing a bunch of 1's into the decoder; watch the number of results grow!</p>
      <h2>In the real world</h2>
      <p>Programmers use algorithms to solve problems every day. New problems always arise; that will never change. It's a programmer's job to create the algorithms that solve them.</p>
      <p>Businesses tasked with protecting their customers' data have developed better methods of encoding and decoding data. Generally, they want to encode messages so only they can decode them, and so the message decodes into only <b>one</b> possible answer. This is reffered to as <b>encryption</b>, and is important for security in the world of computers. <b>Cryptography</b> is an entire field of study oriented around encryption and decryption algorithms, and how to beat them!</p>
      <p>You might recognize that prefix, "crypto", from <b>cryptocurrency</b>. Programmers use cryptographic technology to offer many decentralized currency solutions called cryptocurrencies. <i>Decentralized</i> essentially means the value of the currency is not protected or regulated by any governing entity (like the U.S. government), but rather by the mathematics that define the currency and its transfer. This is just one example of the many ways that cryptography is used creatively to solve problems in our daily lives.</p>
      <p>If you think this web page is cool, consider learning more about programming, cryptography, and computer science. Someday, you could be the one coming up with the new encryption algorithms!</p>
    </div>
  )
}

export default InfoSection;