import React, { ChangeEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import CopyIcon from "./CopyIconSVG";
import { Decoder, isDecodingError } from "./utils";


const CodingTool = () => {
  const[toEncode, setToEncode] = useState<string>("")
  const[toDecode, setToDecode] = useState<string>("")
  const[encoded, setEncoded] = useState<string>("")
  const[decoded, setDecoded] = useState<string[]>([])
  const[numDecoded, setNumDecoded] = useState<number>(0)
  const[encodingError, setEncodingError] = useState<Error | null>(null)
  const[decodingError, setDecodingError] = useState<Error | null>(null)

  const updateToEncode = (e : {target: {value: string}}) => {
    setToEncode(e.target.value)
  }

  const updateToDecode = (e : {target: {value: string}}) => {
    setToDecode(e.target.value)
  }

  const transferEncoded = () => {
    if (!encoded && !encodingError) {
      setEncodingError(Error("Must encode a message first. The button below will copy/paste it to the decoder."))
      return
    }
    setToDecode(encoded)
  }

  useEffect(() => {
    if (!toEncode) {
      console.log("NOT")
      setEncodingError(null)
      setToDecode("")
      setEncoded("")
      return
    }

    const decoder = new Decoder(toEncode)
    try {
      const encodedIn = decoder.encodeString()
      setEncoded(encodedIn)
      setToDecode(encodedIn)
      setEncodingError(null)
    } catch (e) {
      if (isDecodingError(e)) {
        console.log("ERROR", e, "toEnc", toEncode)
        setEncodingError(e.e)
        setToDecode("")
        setEncoded("")
      } else {
        console.log("ELSE", e, toEncode)
      }
    }
  }, [toEncode])

  useEffect(() => {
    if (!toDecode) {
      setDecodingError(null)
      setDecoded([])
      setNumDecoded(0)
      return
    }

    const decoder = new Decoder(toDecode)
    try {
      const decodedIn = decoder.decodeString()
      setDecoded(decodedIn)
      setDecodingError(null)
      setNumDecoded(decodedIn.length)
    } catch (e) {
      if (isDecodingError(e)) {
        // console.log("ERROR", e)
        setDecoded([])
        setDecodingError(e.e)
        if (e.errType === 'complexity') {
          console.log("COMPLEX")
        }
        setNumDecoded(e.data)
      } else {
        console.log("ELSE", e)
      }
    }
  }, [toDecode])

  const handleKeyUp = (e: {key: string}, submit: () => void) => {
    if (e.key === "Enter") {
      submit()
    }
  }

  const renderRow = useCallback(({index, style}: {index: number, style: any}) => {
    return (
      <div style={{...style, left: "16px", top: `${parseFloat(style.top) + 16}px`, width: style.width - 16}}>
        <li key={`decode-item-${index}`}>{decoded[index]}</li>
      </div>
    )
  }, [decoded])

  const innerElementType = useMemo(() => forwardRef(({ style, ...rest }: any, ref) => (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height) + 16 * 2}px`
      }}
      {...rest}
    />
  )), [])

  const listElem: JSX.Element = useMemo(() => {
    return (
      <AutoSizer>
        {({height, width}) => (
          <List width={width} height={height} itemCount={decoded.length} itemSize={24} innerElementType={innerElementType}>{renderRow}</List>
        )}
      </AutoSizer>
    )
  }, [decoded.length, renderRow, innerElementType])

  return (
    <div className="CodingTool">
      <h1>3B's Cryptography Tool</h1>
      <span id="type-prompt">Type in a message to get started!</span>

      <div className="v2">
        <div className="section-wrap">
          <div className="subsection-wrap input-wrap">
            <h2>Encoder</h2>
            <div className="input-area">
              <input value={toEncode} onChange={updateToEncode} maxLength={1000} />
            </div>
          </div>
          <div className="subsection-wrap input-wrap">
            <h2>Decoder</h2>
            <div className="input-area">
              <input value={toDecode} onChange={updateToDecode} maxLength={1000} />
            </div>
            <div className="res-count">Total count: {numDecoded ? numDecoded.toLocaleString("en-US"): "-"}</div>
          </div>
          <div className="subsection-wrap display-area decoded">
            { decodingError ? 
              <span className="error-message dec">{decodingError.message}</span> :
              encodingError ? 
              <span className="error-message dec">{encodingError.message}</span> :
              ( decoded.length === 0 ? 
                <span className="display-placeholder">No input...</span> :
                <>{listElem}</> 
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CodingTool;