
const Footer = () => {
  return (
    <footer className="Footer">
      <hr />
      <span>&copy; 2023 John D. Whiteside</span>
    </footer>
  )
}

export default Footer;